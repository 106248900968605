import ApiService from "@/core/services/api.service";

export const FETCH_SITES = "fetchSite";
export const SITE_COLLECTION = "siteCollection";

const state = {
  errors: null,
  collection: [],
};

const getters = {
  [SITE_COLLECTION](state) {
    return state.collection;
  },

  getSiteByID: (state) => (id) => {
    let item = state.collection.find((item) => item.id == id);

    return item;
  },
};

const actions = {
  [FETCH_SITES](context) {
    return new Promise((resolve) => {
      ApiService.get("site").then(({ data }) => {
        context.commit(FETCH_SITES, data);
        resolve();
      });
    }).catch((error) => {
      console.log("Error!: ", error);
    });
  },
};

const mutations = {
  [FETCH_SITES](state, data) {
    state.collection = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
