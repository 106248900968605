import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import userGroups from "./userGroup.module";
import user from "./user.module";
import role from "./role.module";
import currency from "./currency.module";
import country from "./country.module";
import menu from "./menu.module";
import flatMenu from "./menu_flat.module";
import page from "./page.module";
import site from "./site.module";
import banner from "./banner.module";
import post from "./post.module";
import blogCategory from "./blogCategory.module";
import quotation from "./quotation.module";
import logo from "./logo.module";
import popup from "./popup.module";
import setting from "./setting.module";
import colleague from "./colleague.module";
import cookieCategory from "./cookieCategory.module";
import cookie from "./cookie.module";
import bannerType from "./bannerType.module";
import pageTemplate from "./pageTemplate.module";
import colleagueCategory from "./colleagueCategory.module";
import quotationCategory from "./quotationCategory.module";
import tag from "./tag.module";
import newsCategory from "./news/category.module";
import news from "./news/news.module";
import newsTags from "./news/tag.module";
import publication from "./publications/publication.module";
import publicationCategory from "./publications/publicationCategory.module";
import externalReference from "./externalReference.module";
import gallery from "./gallery.module";
import faq from "./faq.module";
import instructor from "./instructor.module";
import career from "./career.module";
import property from "./product/property.module";
import product from "./product/product.module";
import productCategory from "./product/productCategory.module";
import proceList from "./webShop/priceList.module";
import coupon from "./webShop/coupon.module";
import order from "./webShop/order.module";
import employee from "./humanResource/employee.module";
import year from "./humanResource/year.module";
import contactForm from "./contactForm.module";
import filing from "./filings/filing.module";
import filingBook from "./filings/filingBook.module";
import address from "./address.module";
import eventCategory from "./event/category.module";
import event from "./event/event.module";
import eventTags from "./event/tag.module";
import cafeteriaStatement from "./cafeteriaStatement.module";
import institute from "./institute.module";
import partner from "./partner.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    userGroups,
    user,
    role,
    currency,
    country,
    menu,
    flatMenu,
    page,
    site,
    banner,
    post,
    blogCategory,
    quotation,
    logo,
    popup,
    colleague,
    cookieCategory,
    cookie,
    bannerType,
    pageTemplate,
    colleagueCategory,
    quotationCategory,
    tag,
    newsCategory,
    news,
    newsTags,
    externalReference,
    gallery,
    publication,
    publicationCategory,
    faq,
    career,
    property,
    productCategory,
    product,
    proceList,
    coupon,
    order,
    setting,
    instructor,
    employee,
    contactForm,
    year,
    filing,
    filingBook,
    address,
    eventCategory,
    event,
    eventTags,
    cafeteriaStatement,
    institute,
    partner,
  },
});
