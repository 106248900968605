import i18nService from "@/core/services/i18n.service.js";

export default {
  getTranslated(translations) {
    let appLocale = localStorage.getItem("language");

    if (translations[appLocale]) {
      return translations[appLocale];
    } else {
      return translations[Object.keys(translations)[0]];
    }
  },

  getNicePrice(price, currency) {
    if (!price || price == undefined) return null;

    if (currency == "HUF") {
      return Math.round(price).toLocaleString("hu-HU") + " HUF";
    }

    return (1 * price).toFixed(2).toLocaleString() + " " + currency;
  },

  isObject(object) {
    return object != null && typeof object === "object";
  },

  isEqualObject(obj1, obj2) {
    var props1 = Object.getOwnPropertyNames(obj1);
    var props2 = Object.getOwnPropertyNames(obj2);
    if (props1.length != props2.length) {
      return false;
    }
    for (var i = 0; i < props1.length; i++) {
      let val1 = obj1[props1[i]];
      let val2 = obj2[props1[i]];
      let isObjects = this.isObject(val1) && this.isObject(val2);
      if (
        (isObjects && !this.isEqualObject(val1, val2)) ||
        (!isObjects && val1 !== val2)
      ) {
        return false;
      }
    }
    return true;
  },

  getActiveSiteI18Property(property = null){
    let activeSite = window.localStorage.getItem("active_site")

    let lang = i18nService.getUserActiveLanguage()
    
    if(!activeSite || typeof activeSite === 'undefined' || activeSite === 'undefined') return null;

    activeSite = JSON.parse(activeSite)

    if(!lang || !property ||  !activeSite.custom_fields || !activeSite.custom_fields.i18 || !activeSite.custom_fields.i18[lang] || !activeSite.custom_fields.i18[lang][property]) return null

    return activeSite.custom_fields.i18[lang][property]
  }
};
