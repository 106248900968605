import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./core/services/store";
import ApiService from "./core/services/api.service";
// import MockService from "./core/mock/mock.service";
import { VERIFY_AUTH } from "./core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
// import ClipboardJS from "clipboard";
// window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "./core/plugins/vue-i18n";
import vuetify from "./core/plugins/vuetify";
import Vuelidate from "vuelidate";
import helpers from "./core/plugins/helpers";

import "./core/plugins/portal-vue";
import "./core/plugins/bootstrap-vue";
import "./core/plugins/perfect-scrollbar";
// import "./core/plugins/highlight-js";
import "./core/plugins/inline-svg";
import "./core/plugins/apexcharts";
import "./core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import excel from "vue-excel-export";

import * as Sentry from "@sentry/vue";

import CKEditor from "ckeditor4-vue";
Vue.use(CKEditor);

Vue.use(excel);
// API service init
ApiService.init();

const plugin = {
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  },
};

Vue.use(plugin);

Vue.use(Vuelidate);

router.beforeEach((to, from, next) => {
  // reset config to initial state

  // console.log("to, from", to, from);

  store.dispatch(RESET_LAYOUT_CONFIG);
  if (
    (!from.name || from.name == "login") &&
    to &&
    to.name &&
    to.fullPath.indexOf("cms") == -1
  ) {
    window.localStorage.setItem("TO_PATH", to.fullPath);
  }

  Promise.all([store.dispatch(VERIFY_AUTH)]).then(() => {
    if (!(from.name == "403" || from.name == "404")) next();
  });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;
if(SENTRY_DSN){
  Sentry.init({
    Vue,
    dsn: SENTRY_DSN,
    integrations: [
      /* new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }), */
      Sentry.browserTracingIntegration({ router }),
      /* new Sentry.Replay(), */
      Sentry.replayIntegration(),
    ],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE ?? 0.01,
  
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    tracePropagationTargets: ["localhost",  process.env.VUE_APP_BACKEND_URL],
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });  
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
