import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";
import SiteService from "@/core/services/site.service.js";

const state = {
  errors: null,
  collection: [],
};

const getters = {
  eventCollection(state) {
    return state.collection;
  },

  getEventByID: (state) => (id) => {
    let item = state.collection.find((item) => item.id == id);
    if (!item) {
      item = { name: "" };
    }
    return item;
  },
};

const actions = {
  fetchEvent(context) {
    return new Promise((resolve) => {
      let appLocale = i18nService.getUserActiveLanguage();
      ApiService.get(
        "events/admin/" + SiteService.getActiveSiteId() + "/event"
      ).then(({ data }) => {
        // create localized fields
        let dataLocalized = data.map((item) => {
          item.created = new Date(item.created_at).toLocaleString("hu-HU", {
            timeZone: "UTC",
          });

          item.from = !item.date_from
            ? ""
            : new Date(item.date_from).toLocaleString("hu-HU", {
                timeZone: "UTC",
              });

          item.to = !item.date_to
            ? ""
            : new Date(item.date_to).toLocaleString("hu-HU", {
                timeZone: "UTC",
              });

          if (item.translations) {
            let translations = item.translations[appLocale];
            if (translations) {
              Object.keys(translations).forEach((property) => {
                item[property] = item.translations[appLocale][property];
              });
              item.translated = true;
            } else {
              for (const [key, value] of Object.entries(
                item.translations[Object.keys(item.translations)[0]]
              )) {
                if ("attachments" != key) {
                  item[key] = value;
                }
              }
            }
          }

          return item;
        });

        context.commit("fetchEvent", dataLocalized);
        resolve();
      });
    }).catch((error) => {
      console.log("Error!: ", error);
    });
  },
};

const mutations = {
  fetchEvent(state, data) {
    state.collection = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
