import objectPath from "object-path";
import merge from "deepmerge";
import config from "@/core/config/layout.config.json";
import ApiService from "@/core/services/api.service";
// import i18nService from "@/core/services/i18n.service.js";

// action types
export const SET_LAYOUT_CONFIG = "setLayoutConfig";
export const RESET_LAYOUT_CONFIG = "resetLayoutConfig";
export const OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig";
export const OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig";
// mutation types

export default {
  state: {
    config: config,
    initial: config,
    statuses: [],
    activity: [],
    locales: [],
    permissions: [],
    types: [],
    placements: [],
    auth_user_sites: [],
    expiredDialog: false,
    module_statuses: [],
  },
  getters: {
    /**
     * Get config from layout config
     * @param state
     * @returns {function(path, defaultValue): *}
     */
    layoutConfig: (state) => (path, defaultValue) => {
      return objectPath.get(state.config, path, defaultValue);
    },

    statuses(state) {
      return state.statuses;
    },

    activity(state) {
      return state.activity;
    },

    locales(state) {
      return state.locales;
    },

    types(state) {
      return state.types;
    },

    placements(state) {
      return state.placements;
    },

    permissions(state) {
      return state.permissions;
    },

    expiredDialog(state) {
      return state.expiredDialog;
    },

    authUserSites(state) {
      return state.auth_user_sites;
    },
    module_statuses(state) {
      return state.module_statuses;
    },
  },
  actions: {
    /**
     * Set and replace the whole config
     * @param state
     * @param payload
     */
    [SET_LAYOUT_CONFIG](state, payload) {
      state.commit(SET_LAYOUT_CONFIG, payload);
    },

    /**
     * Reset the config at the initial state
     * @param state
     */
    [RESET_LAYOUT_CONFIG](state) {
      state.commit(RESET_LAYOUT_CONFIG);
    },

    /**
     * Reset the config using saved config in the cache by the layout builder
     * @param state
     */
    [OVERRIDE_LAYOUT_CONFIG](state) {
      state.commit(OVERRIDE_LAYOUT_CONFIG);
    },

    /**
     * Override config by page level
     * @param state
     * @param payload
     */
    [OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
      state.commit(OVERRIDE_PAGE_LAYOUT_CONFIG, payload);
    },

    // getStatuses(state) {
    //   state.commit("getStatuses");
    // },

    getConfig(state) {
      state.commit("getConfig");
    },

    openExpiredDialog(state) {
      state.commit("setExpiredDialog", true);
    },

    closeExpiredDialog(state) {
      state.commit("setExpiredDialog", false);
    },
  },
  mutations: {
    [SET_LAYOUT_CONFIG](state, payload) {
      state.config = payload;
    },
    [RESET_LAYOUT_CONFIG](state) {
      state.config = Object.assign({}, state.initial);
    },
    [OVERRIDE_LAYOUT_CONFIG](state) {
      state.config = state.initial = Object.assign(
        {},
        state.initial,
        JSON.parse(localStorage.getItem("config"))
      );
    },
    [OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
      state.config = merge(state.config, payload);
    },
    // getStatuses(state) {
    //   ApiService.get("status")
    //     .then(({ data }) => {
    //       state.statuses = data
    //       return state.statuses
    //     })
    //     .catch((error) => {
    //       console.log('Error!: ', error);
    //     });
    // },

    getConfig(state) {
      ApiService.get("config")
        .then(({ data }) => {
          let prevPermissions = window.localStorage.getItem("permissions");
          window.localStorage.setItem(
            "permissions",
            JSON.stringify(data.permissions)
          );
          if (!window.localStorage.getItem("types")) {
            window.localStorage.setItem("types", JSON.stringify(data.types));
          }
          state.statuses = data.statuses;
          state.activity = data.activity;
          state.locales = data.locales;
          state.permissions = data.permissions;
          state.types = data.types;
          state.placements = data.placements;
          state.auth_user_sites = data.sites ?? [];
          state.module_statuses = data.module_statuses;

          // if (localStorage.getItem("active_site_id")) {
          //   let site = data.sites.find(
          //     (item) => item.id == localStorage.getItem("active_site_id")
          //   );
          //   console.log(site);
          //   i18nService.setLanguages(site.locales);
          // }

          if (prevPermissions != window.localStorage.getItem("permissions")) {
            location.reload();
          }
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    setExpiredDialog(state, status) {
      state.expiredDialog = status;
    },
  },
};
