import ApiService from "@/core/services/api.service";

export const FETCH_CURRENCY = "fetchCurrency";
export const CURRENCY_COLLECTION = "currencyCollection";

const state = {
  errors: null,
  collection: [],
};

const getters = {
  [CURRENCY_COLLECTION](state) {
    return state.collection;
  },
};

const actions = {
  [FETCH_CURRENCY](context) {
    ApiService.get("currency")
      .then(({ data }) => {
        context.commit(FETCH_CURRENCY, data);
      })
      .catch((error) => {
        console.log("Error!: ", error);
      });
  },
};

const mutations = {
  [FETCH_CURRENCY](state, data) {
    state.collection = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
